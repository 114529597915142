import React from 'react';
import './Cards.css';
import CardItem, {HomeCardItem} from './CardItem';

function AUCards() {
  return (
    <div className='cards'>
      <h1>Dr Medha Pendse</h1>
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
            <CardItem
              src='images/img6.jpg'
              text={
                <>
                    <p>Singapore Homeopathy Center is a forward-thinking clinic dedicated to healing sickness and promoting healthy lifestyles. Our treatments don’t just focus on your symptoms - they concentrate on the root of the problem.</p>
                    <br></br>
                    <p>We begin each session with a comprehensive patient review, designed to understand your unique health patterns and behavior. Then we create individualized treatment methods tailor-made for you. We closely track the results and modify treatments as necessary. </p>
                    <br></br>
                    <p>We provide Homeopathic remedies and counseling in a single consultation.</p>
                    <br></br>
                    <p>We consider ourselves educators as well as healers since we teach each patient about the strength that they carry within their own bodies. Start your healing journey today by giving us a call.</p>
                </>
            }
              label='Dr Medha Pendse'
              path='/services'
            />
          </ul>
          <ul className='cards__items'>
            <HomeCardItem
              title='Our Goals'
              text={
                <>
                    <li>To treat the patient as a whole instead of just treating disease manifestations</li>
                    <li>To provide affordable and reliable care</li>
                    <li>To provide Homeopathic remedies, counseling, dietary advice, & exercise routines all in a single consultation</li>
                </>
            }
              label='Dr Medha Pendse'
            />
            <HomeCardItem
              title='Our Mission'
              text={
                <>
                    <p>Dr. Samuel Hahnemann once said, “The sole mission of the physician is to cure rapidly, gently and permanently“. We abide by the mission set forth by the founder of homeopathy.</p>
                </>
            }
              label='Dr Medha Pendse'
            />
          </ul>
          <ul>
          <HomeCardItem
              title='Our Vision'
              text={
                <>
                    <p>'Dare to be wise' said the founder of Homeopathy, Dr. Hahnemann. We abide by this principle & DARE TO BE WISE</p>
                    <br></br>
                    <p>As we know, one line of treatment may not fit all. Each individual is different and needs customized care. When a patient says he has a headache, we should not just give a painkiller to hide the symptoms we must understand the cause behind it. The cause can be different for different patients, so we must provide customized care to each one of them so that the patient is cured internally and permanently. </p>
                </>
            }
              label='Dr Medha Pendse'
            />
          </ul>
        </div>
      </div>
    </div>
  );
}

export default AUCards;
