import React from 'react';
import '../../App.css';
import Footer from '../Footer';
import AUCards from '../AUCards';

export default function AboutUs() {
  return (
    <>
    <h1 style={{ fontSize: '10vh', padding: '1.5vw' }}>About Us</h1>
    <AUCards />
    <Footer />
    </>
    );
}
