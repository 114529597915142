import React from 'react';
import './Footer.css';
import { Button } from './Button';
import { Link } from 'react-router-dom';

function redirectToWhatsApp() {
  // Replace the phone number with the recipient's phone number
  const phoneNumber = '97310111';

  // Construct the WhatsApp API URL
  const whatsappUrl = `https://wa.me/${phoneNumber}`;

  // Redirect the user to the WhatsApp chat
  window.location.href = whatsappUrl;
}

function Footer() {
  return (
    <div className='footer-container'>
      <br></br>
      <Button
          className='btns'
          buttonStyle='btn--outline'
          buttonSize='btn--large'
          onClick={redirectToWhatsApp}
        >
          {<><p>Message us on WhatsApp NOW!</p></>}
          <i class='fab fa-whatsapp'></i>
        </Button>
    </div>
  );
}

export default Footer;
