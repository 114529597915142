import React from 'react';
import './Cards.css';
import CardItem, {HomeCardItem} from './CardItem';
import Reviewbar from './reviewbar';

function redirectToWhatsApp() {
  const phoneNumber = '97310111'; // Replace with the actual phone number
  const whatsappUrl = `https://wa.me/${phoneNumber}`;
  window.location.href = whatsappUrl;
}

function redirectToGmail() {
  const emailAddress = 'medha.pendse123@gmail.com'; // Replace with the actual email address
  const subject = 'Subject Here'; // Replace with the actual subject
  const body = 'Body text here'; // Replace with the actual body text
  const mailtoUrl = `mailto:${emailAddress}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
  window.location.href = mailtoUrl;
}

function AttentionButtons() {
  return (
    <div className="button-container">
      <button className="attention-button" onClick={redirectToWhatsApp}>
        Message us on WhatsApp!
        <i className="fab fa-whatsapp"></i>
      </button>
      <button className="attention-button gmail" onClick={redirectToGmail}>
        Send us an Email!
        <i className="fas fa-envelope"></i>
      </button>
    </div>
  );
}

function HomeCards() {
  return (
    <div className='cards'>
      <h1 style={{ fontSize: '5vh', padding: '2vw' }}>SINGAPORE HOMEOPATHY CENTER</h1>
      <div className='cards__container'>
      <h5 style={{ fontSize: '2.5vh', padding: '0.5vw' }}>Click the buttons below to contact us! By appointment only!</h5>
        <AttentionButtons />
        <br></br><br></br><br></br>
        <h1>YOUR GATEWAY TO A HEALTHY LIFE</h1>
        <br></br>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
            <CardItem
              src='images/img6.jpg'
              text={
                <>
                    <p>With years of experience under her belt, Dr. Medha Pendse has treated countless patients suffering from a wide variety of ailments. A qualified homeopath, with B.H.M.S. (Bachelor of Homeopathy Medicine and Surgery) from the University of Mumbai and a Diploma in Hospital and Health Care Management, Dr. Medha has also worked in a plethora of hospital wards, including the Intensive Cardiac Care Unit, Medical Intensive Care Unit, Medical Ward & Surgical Ward.</p>
                    <br></br>
                    <p>Dr. Medha has worked in India for the better part of a decade as a General Practitioner for alternative medicine (Homeopathy) and in a hospital set up as a Resident Medical Officer. She has also worked in Singapore at Mount Elizabeth Novena Hospital and Gleneagles Hospital with the clinical coding department. Driven by her passion to heal and ingrained with unrivalled medical knowledge, Dr. Medha ensures all her patients leave her center completely satisfied.</p>
                    <br></br>
                    <p style={{ textAlign: 'center' }}><u>Schedule a session today to begin your healing process.</u></p>
                </>
            }
              label='Dr Medha Pendse'
            />
          </ul>
          <h1 style={{ fontSize: '5vh', padding: '2vw' }}>Voices of Recovery</h1>
          <Reviewbar />
          <div className="review-buttons">
            <a href="https://www.google.com/maps/place/Singapore+Homeopathy+Center/@1.3745732,103.9031123,15z/data=!3m1!5s0x31da16253388eded:0x8166f3476d08e2eb!4m8!3m7!1s0x31da175f59ee4441:0x25bb223cd9447e23!8m2!3d1.3745732!4d103.9031123!9m1!1b1!16s%2Fg%2F11n0zggykv?entry=ttu" target="_blank" rel="noopener noreferrer" className="review-button">
              Click here to view all our reviews
            </a>
            <a href="https://g.page/r/CSN-RNk8IrslEAE/review" target="_blank" rel="noopener noreferrer" className="review-button">
              Write your own review!
            </a>
          </div>
          <br />
          <h1 style={{ fontSize: '5vh', padding: '2vw' }}>Our Location</h1>
          <ul className='cards__items'>
            <HomeCardItem
              title='Address'
              text={
                <>
                    74 Upper Serangoon View, S533881
                    <br></br><br></br>
                    <iframe
                      width="100%"
                      height="400"
                      frameborder="0"
                      scrolling="no"
                      marginheight="0"
                      marginwidth="0"
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.6701768692587!2d103.90053737599389!3d1.3745785614937382!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da175f59ee4441%3A0x25bb223cd9447e23!2sSingapore%20Homeopathy%20Center!5e0!3m2!1sen!2ssg!4v1716889783892!5m2!1sen!2ssg"
                    ></iframe>
                    <br />
                </>
            }
            />
            <HomeCardItem
              title='Opening Hours'
              text={
                <>
                  <br></br>
                  {/* <ul style={{ color: 'red' }}>Clinic is closed from 31st October to 3rd November</ul>
                  <ul style={{ color: 'red' }}> Regular service resumes from 4th November</ul><br></br> */}
                  <ul>Monday    - 10:30am to 7:30pm</ul><br></br>
                  <ul>Tuesday   - 10:30am to 7:30pm</ul><br></br>
                  <ul>Wednesday - 10:30am to 7:30pm</ul><br></br>
                  <ul>Thursday  - 10:30am to 7:30pm</ul><br></br>
                  <ul>Friday    - 10:30am to 7:30pm</ul><br></br>
                  <ul>Saturday  - 11am to 6pm</ul><br></br>
                  <ul>Sunday & Public Holidays - Closed</ul><br></br>
                  <ul style={{ color: 'red' }}>Strictly by appointment only.</ul>
                </>
            }
              label='Dr Medha Pendse'
            />
          </ul>
        </div>
      </div>
    </div>
  );
}

export default HomeCards;
