import React, { useState } from 'react';
import '../../App.css';
import '../Register.css';
import emailjs from 'emailjs-com';

const Register = () => {
  const redirectToWhatsApp = () => {
    const phoneNumber = '97310111';
    const whatsappUrl = `https://wa.me/${phoneNumber}`;
    window.location.href = whatsappUrl;
  };

  function redirectToGmail() {
    const emailAddress = 'medha.pendse123@gmail.com'; // Replace with the actual email address
    const subject = 'Subject Here'; // Replace with the actual subject
    const body = 'Body text here'; // Replace with the actual body text
    const mailtoUrl = `mailto:${emailAddress}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    window.location.href = mailtoUrl;
  }

  const [formData, setFormData] = useState({
    name: '',
    contactNumber: '',
    message: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs.sendForm(
      'service_ib2u4ug', // Replace with your EmailJS service ID
      'template_oc5u4nd', // Replace with your EmailJS template ID
      e.target,
      'COTIIBX1fHrQmjWo_' // Replace with your EmailJS user ID
    ).then(
      (result) => {
        console.log(result.text);
        alert('Message sent successfully! We will get back to you soon!');
      },
      (error) => {
        console.log(error.text);
        alert('Failed to send message, please try again.');
      }
    );

    setFormData({
      name: '',
      contactNumber: '',
      message: ''
    });
  };

  return (
    <div className="contact-us">
      <h2>Contact Us</h2>
      <p>
        We are here to help you on your journey to better health with homeopathy. 
        For any clarifications on how homeopathy can help you, feel free to get in touch with us.
      </p>
      <div className="map-container">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.6701768692587!2d103.90053737599389!3d1.3745785614937382!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da175f59ee4441%3A0x25bb223cd9447e23!2sSingapore%20Homeopathy%20Center!5e0!3m2!1sen!2ssg!4v1716889783892!5m2!1sen!2ssg"
          width="600"
          height="450"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          title="Our Location"
        ></iframe>
      </div>
      <br />
      <p>Click the buttons below to contact us about bookings and enquiries!</p>
      <div className="button-container">
        <button className="attention-button" onClick={redirectToWhatsApp}>
          Contact us on WhatsApp Now! <i className="fab fa-whatsapp"></i>
        </button>
        <button className="attention-button gmail" onClick={redirectToGmail}>
          Send us an Email <i className="far fa-envelope"></i>
        </button>
      </div>
      <br />
      <p>Call us at +65 97310111</p>
      <br />
      <p>Alternatively, fill out the form below and we will get in touch with you!</p>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label>Name</label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label>Contact Number</label>
          <input
            type="text"
            name="contactNumber"
            value={formData.contactNumber}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label>Message</label>
          <textarea
            name="message"
            value={formData.message}
            onChange={handleChange}
            required
          ></textarea>
        </div>
        <button type="submit" className="submit-button">Send Message</button>
      </form>
    </div>
  );
};


export default Register;
