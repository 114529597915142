import React, {useState} from 'react';
import './reviewbar.css';

const reviews = [
    { 
      author: "Mohsina Rizvi", 
      text: "Dr. Medha is an exceptional doctor whose expertise and compassion make her stand out in her field. From the moment I stepped into her office, I felt at ease knowing I was in capable hands. Dr. Medha took the time to listen attentively to my concerns, thoroughly explaining the treatment options in a way that was easy to understand. Her professionalism and genuine care for her patients are evident in every interaction. I highly recommend Dr. Medha to anyone seeking homeopathy care." 
    },
    { 
      author: "Sanya Aggarwal", 
      text: "I have been consulting with Dr. Medha for some time now and her dedication and expertise have been immensely beneficial to me. Whether it's routine health concerns or emergencies during travel, she has always been remarkably responsive and attentive. Her prescribed treatments have consistently been effective for my toddler, husband and me, showcasing her skill in tailoring remedies to individual needs of all age groups. Thank you Dr for all that you have done and continue to do." 
    },
    { 
      author: "Puja Surana", 
      text: "Dr Medha came as a life saviour for me during Covid when I had eczema on my hands . I was done with allopathy treatment and applying tons of lotions/ cream / ointment for no good.<br />But then i met Dr Medha who guided me through day in and out. She is very patient , smiling to everyone. Her diagnosis and treatment helped me get rid of eczema completely. I gained faith on homeopathy only after getting in touch with dr medha. I will always be grateful to her and her knowledge about homeopathy." 
    },
    { 
      author: "Bhavani Rathakrishnan", 
      text: "My mum's (age 72) health was slowly deteriorating in 2022. She had lost 12kg of her body weight in the span of 11mths from jan'22 to nov'22. Hospital medicine made her very weak & didn't cure her root cause. She was always seen letargic and she went she into mild depression due to her stomach issues. Thankfully by God's grace, i chanced upon Ms. Medha review here. Brought my mum to see her personally on Nov'22, she reviewed my mum's full medical condition and gave the right treatment of her Homeopathy medicines. Within few weeks, there was drastic improvement on my mum. She got her appetite back! No more stomach issues! She started to put on weight and she's a happy person now. All thanks to Ms.Medha. Till today my mum is still taking her advise on what to eat and medications. We are truly glad to have met her. Her fees are very reasonable compared to other places. She is a very nice person. Please look for her for any treatment or chronic disease. Western medication didnt help my mum, but homeopathy did!" 
    },
    {
        author: "Binnie Abraham",
        text: "I was having acute gastric issue which also included migraine. Earlier I had tried all sorts of medicines and diet plans. It was a hassle but even after that nothing helped. I met Dr.Medha  3 months back and with her treatment I saw significant improvement in my health. Also she suggested nominal changes to my diet which was very easy to incorporate. Im thankful to Dr.Medha for everything."
    }
  ];
  
  const Reviewbar = () => {
    const [expandedReview, setExpandedReview] = useState({});
  
    const toggleExpand = (index) => {
      setExpandedReview((prev) => ({
        ...prev,
        [index]: !prev[index]
      }));
    };
  
    return (
      <div className="reviews-container">
        {reviews.map((review, index) => (
          <div className={`review-box ${expandedReview[index] ? 'expanded' : ''}`} key={index}>
            <h4 className="review-author">{review.author}</h4>
            <p dangerouslySetInnerHTML={{ __html: review.text }} />
            <button onClick={() => toggleExpand(index)}>
              {expandedReview[index] ? 'Show Less' : 'Read More'}
            </button>
          </div>
        ))}
      </div>
    );
  };

export default Reviewbar;
