import React from 'react';
import Navbar from './components/Navbar';
import './App.css';
import Home from './components/pages/Home';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import AboutUs from './components/pages/AboutUs';
import Register from './components/pages/Register';
import Treatments from './components/pages/Treatments';
import { Analytics } from "@vercel/analytics/react"
import { SpeedInsights } from "@vercel/speed-insights/react"

function App() {
  return (
    <>
      <Router>
        <Navbar />
        <Routes>
          <Route path='/' exact element={ <Home />}></Route>
          <Route path='/aboutus' exact element={ <AboutUs />}></Route>
          <Route path='/treatments' exact element={ <Treatments />}></Route>
          <Route path='/register' exact element={ <Register />}></Route>
        </Routes>
      </Router>
      <Analytics/>
      <SpeedInsights/>
    </>
  );
}

export default App;
