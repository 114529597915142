import React from 'react';
import '../../App.css';
import Cards from '../Cards';
import Footer from '../Footer';

export default function Treatments() {
  return (
  <>
  {/* <h1 style={{ fontSize: '5vh', padding: '5vw' }}>Singapore Homeopathy Center</h1> */}
  <Cards />
  <Footer />
  </>
  );
}
