import React from 'react';
import './Cards.css';
import CardItem, {HomeCardItem} from './CardItem';

function Cards() {
  return (
    <div className='cards'>
      <h1>Homeopathy provides a holistic cure to the following diseases and many more!</h1>
      <br></br><br></br>
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
            <HomeCardItem
                src='images/mind.jpeg'
                text='Homeopathy can be used in all acute and chronic conditions for everyone: from infants and pregnant women to geriatric patients.
                It is the best alternative medicine, and can be taken simultaneously with other medicines.
                '
                label='Mind'
              />
          </ul>
        </div>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
            <CardItem
              src='images/mind.jpeg'
              text='•	Anxiety
              •	Insomnia
              •	Any symptoms due to fear, loss of loved ones
              •	Issues with repeated dreams
              •	Dementia
              •	Depression
              •	ADHD
              •	ASD
              •	PAN & PANDAS
              '
              label='Mind'
            />
            <CardItem
              src='images/head.jpeg'
              text='•	Migraine
              •	Headache
              •	Vertigo
              '
              label='Head'
            />
            <CardItem
              src='images/eye.jpeg'
              text='•	Dryness
              •	Itching
              •	Recurrent styes
              '
              label='Eye'
            />
            <CardItem
              src='images/ear.png'
              text='•	Itching
              •	Pain
              •	Recurrent infections
              •	Tinnitus (noise or ringing in the ear)
              '
              label='Ear'
            />
          </ul>
          <ul className='cards__items'>
            <CardItem
              src='images/nose.jpeg'
              text='•	Recurrent cold
              •	Blocked nose
              •	Allergic rhinitis
              •	Loss of smell
              •	Nasal polyps
              •	Sneezing tendencies
              '
              label='Nose'
            />
            <CardItem
              src='images/face.jpeg'
              text='•	Acne
              •	Eruptions
              '
              label='Face'
            />
            <CardItem
              src='images/mouth.jpeg'
              text='•	Aphthae
              •	Abscess
              •	Gum swelling or bleeding
              •	Toothache
              •	Difficult dentition in children
              '
              label='Mouth'
            />
            <CardItem
              src='images/throat.jpeg'
              text='•	Pain
              •	Tonsillitis
              •	Pharyngitis
              •	Laryngitis
              •	Goiter
              •	Torticollis
              '
              label='Throat'
            />
          </ul>
          <ul className='cards__items'>
            <CardItem
              src='images/stomach.png'
              text='•	Loss of appetite
              •	Acidity
              •	Eructation
              •	Gastritis
              •	Hiccup
              •	Nausea
              •	Vomiting
              •	Distension
              •	Diarrhea
              •	Flatulence
              •	IBS
              '
              label='Stomach'
            />
            <CardItem
              src='images/rectum.jpeg'
              text='•	Constipation
              •	Piles/ Haemorrhoids
              •	Itching/burning
              •	Worms
              •	Fistula-in-Ano
              •	Anal fissure
              '
              label='Rectum'
            />
            <CardItem
              src='images/kidney.jpeg'
              text='•	Recurrent urinary tract infection
              •	Recurrent kidney stones
              '
              label='Kidney'
            />
            <CardItem
              src='images/gynecology.jpeg'
              text='•	Menstrual problems like menorrhagia, metrorrhagia, and delayed menarche
              •	Menopausal issues
              •	Leucorrhoea
              •	Eruptions
              '
              label='Gynecology Problems'
            />
          </ul>
          <ul className='cards__items'>
            <CardItem
              src='images/lungs.jpeg'
              text='•	Cough
              •	Asthma
              •	Bronchitis
              '
              label='Respiratory Problems'
            />
            <CardItem
              src='images/joints.jpeg'
              text='•	Backache
              •	Knee pain
              •	Any joint or bone pains
              •	Rheumatoid arthritis
              '
              label='Joint Problems'
            />
            <CardItem
              src='images/sleep.jpeg'
              text='•	Sleep disorders
              •	Nightmares
              •	Snoring
              •	Insomnia
              •	Sleep apnea
              '
              label='Sleep'
            />
            <CardItem
              src='images/skin.jpeg'
              text='•	Eczema
              •	Eruption
              •	Dandruff
              •	Allergic rash
              •	Urticarial rash
              •	Psoriasis
              •	Lichen planus
              '
              label='Skin'
            />
          </ul>
          <ul className='cards__items'>
            <CardItem
              src='images/cancer.jpeg'
              text='•	Palliative treatment for cancer patients
              '
              label='Cancer'
            />
            <CardItem
              src='images/counselling.jpeg'
              text='•	Marriage counselling
              •	Kids counselling
              '
              label='Counselling'
            />
            <CardItem
              src='images/diet.jpeg'
              text='•	Dietary advice for Diabetes, Hypertension & Hyperlipidaemia
              '
              label='Diet'
            />
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Cards;
